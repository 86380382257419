import getConfig from 'next/config';
import Link from 'next/link';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import FooterMainFragment from 'components/FooterMainFragment/FooterMainFragment';
import HubspotFormWithStyle from 'components/HubspotFormWithStyle/HubspotFormWithStyle';
import AppleStoreBadge from 'components/Icons/AppleStoreBadge/AppleStoreBadge';
import GooglePlayBadge from 'components/Icons/GooglePlayBadge/GooglePlayBadge';
import LogoIcon from 'components/Icons/Logo/Logo';
import SocialMediaButtons from 'components/SocialMediaButtons/SocialMediaButtons';
import { useApp } from 'contexts/appContext';
import { IMenu } from 'models/menu';

import s from './FooterStandard.module.scss';

const { publicRuntimeConfig } = getConfig();

type FooterStandardProps = {
  menu: IMenu[];
  hubspotFormID?: string;
  onClickCallToAction?: () => void;
  callToActionDisabled?: boolean;
};
const FooterStandard: React.FC<FooterStandardProps> = ({
  menu,
  hubspotFormID,
  onClickCallToAction,
  callToActionDisabled,
}) => {
  const {
    facebookFoundationPage,
    instagramFoundationUser,
    twitterFoundationUser,
    youtubeFoundationChannel,
  } = useApp();
  return (
    <footer className="mt-5 mt-md-9">
      <div className={cx('bg-secondary py-5', s['bg-secondary'])}>
        <Container>
          <Row>
            <Col md={7}>
              <div className={cx('mb-4', s['col-text'])}>
                <h2 className="mb-4">Download the Beach Safe app today</h2>
                <p>
                  Quickly and easily find out if a beach is patrolled, see
                  detailed weather information and check the tide, swell and
                  wind forecasts
                </p>
              </div>
              <Row className="m-0 align-items-center">
                <a
                  className="d-inline-block max-w-100 mr-3"
                  href="https://apps.apple.com/au/app/beachsafe/id397699623"
                >
                  <AppleStoreBadge width="10em" height="auto" />
                </a>
                <a
                  className="d-inline-block max-w-100"
                  href="https://play.google.com/store/apps/details?id=com.sls.beachsafe"
                >
                  <GooglePlayBadge width="10em" height="auto" />
                </a>
              </Row>
            </Col>
            <Col md={5} className="position-static">
              <img
                width="auto"
                height="auto"
                loading="lazy"
                className={s['mobile-phone-image']}
                src="/imgs/Mobile-phone.png"
                alt="Mobile phone"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-primary py-5">
        <Container>
          <Row>
            <Col lg={5}>
              <Row className="m-0 justify-content-between align-items-center align-items-sm-start">
                <Link href="/" passHref>
                  <a>
                    <LogoIcon height="7em" width="7em" />
                  </a>
                </Link>
                {!callToActionDisabled && (
                  <Button onClick={onClickCallToAction} variant="danger">
                    DONATE NOW
                  </Button>
                )}
              </Row>
              <div className="social-media my-4">
                <h6 className="text-white">Follow us</h6>
                <SocialMediaButtons
                  facebookFoundationPage={facebookFoundationPage}
                  instagramFoundationUser={instagramFoundationUser}
                  twitterFoundationUser={twitterFoundationUser}
                  youtubeFoundationChannel={youtubeFoundationChannel}
                />
              </div>
            </Col>
            <Col lg={7}>
              <Button
                as="a"
                className="text-white text-decoration-underline font-weight-semi-bold px-0"
                variant="link"
                href="https://www.surflottery.com.au/"
              >
                Visit Surf Lotteries
              </Button>
              <Button
                as="a"
                className="text-white text-decoration-underline font-weight-semi-bold px-0 ml-0 ml-md-3"
                variant="link"
                href="https://sls.com.au/"
              >
                Visit Surf Life Saving Australia
              </Button>
              <p className="text-white mt-4">
                Sign up for the newsletter to receive updates on how your
                donation has helped Surf Life Saving, plus hear about real life
                rescue stories and exciting new ventures.
              </p>
              <Row>
                <Col className={s['hubspot-form-col']}>
                  <HubspotFormWithStyle
                    theme="dark"
                    formId={
                      hubspotFormID ||
                      publicRuntimeConfig.hubspotNewsletterFormID
                    }
                    loading={<div>Loading...</div>}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterMainFragment menu={menu} />
    </footer>
  );
};

export default FooterStandard;
